import styled from "styled-components";
import { PiGridNineLight, PiWallLight } from "react-icons/pi";
import { PiRulerLight } from "react-icons/pi";
import { useAppStore } from "@/stores/appStore";
import translations, { DEFAULT_LOCALE } from "@/translations";

const StyledOverlay = styled.div`
	position: absolute;
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 100%;
	padding: 2rem;
	gap: 1rem;

	button {
		padding: 1rem;
		border: none;
		border-radius: 50%;
		color: ${({ theme }) => theme.colors.grey};
		background-color: ${({ theme }) => theme.colors.white};
		box-shadow: 0 0 1rem rgb(from ${({ theme }) => theme.colors.grey} r g b / 0.2);
		cursor: pointer;

		display: flex;
		justify-content: center;
		align-items: center;
	}

	button > svg {
		width: 1rem;
		height: 1rem;
	}
`;

const Overlay = () => {
	const showWalls = useAppStore((store) => store.showWalls);
	const showGrid = useAppStore((store) => store.showGrid);
	const showMeasurements = useAppStore((store) => store.showMeasurements);
	const update = useAppStore((store) => store.update);

	return (
		<StyledOverlay>
			<button
				title={showWalls ? translations.hideWalls[DEFAULT_LOCALE] : translations.showWalls[DEFAULT_LOCALE]}
				onClick={() => update({ showWalls: !showWalls })}>
				<PiWallLight />
			</button>
			<button
				title={showGrid ? translations.hideGrid[DEFAULT_LOCALE] : translations.showGrid[DEFAULT_LOCALE]}
				onClick={() => update({ showGrid: !showGrid })}>
				<PiGridNineLight />
			</button>
			<button
				title={
					showMeasurements
						? translations.hideMeasurements[DEFAULT_LOCALE]
						: translations.showMeasurements[DEFAULT_LOCALE]
				}
				onClick={() => update({ showMeasurements: !showMeasurements })}>
				<PiRulerLight />
			</button>
		</StyledOverlay>
	);
};

export default Overlay;
