import { Canvas } from "@react-three/fiber";
import Performance from "./Performance";
import Lights from "./Lights";
import Controls from "./Controls";
import Effects from "./Effects";
import Product from "./Product";
import Grid from "./Grid";
import { Suspense } from "react";

interface SceneProps {
	className?: string;
}
export default function Scene({ className }: SceneProps) {
	return (
		<Canvas
			className={className}
			dpr={1.5}
			shadows
			frameloop="demand"
			camera={{ fov: 45, position: [0, 6, 8] }}
			style={{ width: "100%", height: "100svh" }}>
			<Performance>
				<Controls />
				<Lights />
				<Effects />
				<Suspense fallback={null}>
					<Product />
				</Suspense>
				<Grid />
			</Performance>
		</Canvas>
	);
}
