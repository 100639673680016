import { selectors, useAppStore, WallLocation } from "@/stores/appStore";
import { startTransition, useEffect, useState } from "react";

/* This hook exists solely as a wrapper around the store.
 * Transitions are not supported for Zustand, so the store value
 * needs to be wrapped in state so that the UI is only updated once
 * the transition is complete.
 */
export default function useWall(location: WallLocation) {
	const wall = useAppStore(selectors.getWallByLocation(location));
	const [wallState, setWallState] = useState(wall);

	useEffect(() => {
		startTransition(() => {
			setWallState(wall);
		});
	}, [wall]);

	return wallState;
}
