import { isMeshStandardMaterial } from "@/utils";
import { invalidate } from "@react-three/fiber";
import { useLayoutEffect } from "react";
import { Color, Material } from "three";

const useColors = (materials: Record<string, Material>, colorMap: Record<string, string | Color>) => {
	useLayoutEffect(() => {
		for (const [key, color] of Object.entries(colorMap)) {
			const material = materials[key];
			if (isMeshStandardMaterial(material)) {
				if (typeof color === "object") {
					material.color.copy(color);
				} else {
					material.color.set(color);
				}

				material.metalness = 0;
				material.roughness = 1;
			}
		}
		invalidate();
	}, [materials, colorMap]);
};

export default useColors;
