import { PropsWithChildren } from "react";
import styled, { css } from "styled-components";

type CardProps = PropsWithChildren<{ isActive?: boolean }>;

export default function Card({ children, isActive }: CardProps) {
	return <CardContainer $active={isActive}>{children}</CardContainer>;
}

const CardContainer = styled.div<{ $active?: boolean }>`
	width: 100%;
	display: flex;
	gap: ${({ theme }) => theme.gap.medium};
	padding: ${({ theme }) => theme.padding.extra_large};
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	background-color: white;

	border: 1px solid transparent;
	transition: border-color 0.15s ease;

	&:hover {
		border-color: ${({ theme }) => theme.colors.light_grey};
	}

	${({ $active }) =>
		$active &&
		css`
			border-color: ${({ theme }) => theme.colors.grey} !important;
		`}
`;
