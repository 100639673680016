import CONFIG from "@/config";
import { selectors, useAppStore, WallLocation } from "@/stores/appStore";
import { GroupProps } from "@react-three/fiber";
import { FunctionComponent, lazy } from "react";
import EmptyWall from "./23FA-00";
import { isLeftDoorSegment, isWindowSegment } from "@/utils";
import { useGLTF } from "@react-three/drei";
import useColors from "@/hooks/useColors";
import useWall from "@/hooks/useWall";
import WithCursor from "../WithCursor";

const Window = lazy(() => import("./SW"));
const Door = lazy(() => import("./DL"));

interface WallProps extends GroupProps {
	location: WallLocation;
}

const Wall: FunctionComponent<WallProps> = ({ location, ...otherProps }) => {
	const wall = useWall(location);
	const wallColor = useAppStore(selectors.getWallColor);
	const { materials } = useGLTF("/models/23FA-00.glb");

	useColors(materials, {
		["Wall -Exterior"]: wallColor,
		["Wall - Skirting"]: CONFIG.colors.anthracite,
	});

	const emptyWallSize = CONFIG.wallSizes.inner.S;
	const scalingFactor = wall.innerWidth / emptyWallSize;
	const inverseScalingFactor = 1 / scalingFactor;

	return (
		<WithCursor>
			<EmptyWall {...otherProps} location={location} scale-x={scalingFactor}>
				{wall.segments.map((segment, i) => {
					// Percentage along the x axis.
					const percentage = (2 * i + 1) / (2 * wall.segments.length);
					const xPosition = (percentage - 0.5) * wall.innerWidth * inverseScalingFactor;

					return isWindowSegment(segment) ? (
						<Window key={segment} position={[-xPosition, CONFIG.windowHeight, 0]} scale-x={inverseScalingFactor} />
					) : isLeftDoorSegment(segment) ? (
						<Door key={segment} position={[-xPosition, CONFIG.doorStepHeight, 0]} scale-x={inverseScalingFactor} />
					) : null;
				})}
			</EmptyWall>
		</WithCursor>
	);
};

export default Wall;
