import { useCursor } from "@react-three/drei";
import { GroupProps } from "@react-three/fiber";
import { useCallback, useState } from "react";

export default function WithCursor({ children, ...props }: GroupProps) {
	const [hovered, setHovered] = useState(false);
	const onPointerEnter = useCallback(() => setHovered(true), []);
	const onPointerLeave = useCallback(() => setHovered(false), []);

	useCursor(hovered);

	return (
		<group {...props} onPointerEnter={onPointerEnter} onPointerLeave={onPointerLeave}>
			{children}
		</group>
	);
}
