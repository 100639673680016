import translations, { DEFAULT_LOCALE } from "@/translations";
import { Link } from "@tanstack/react-router";
import styled from "styled-components";

const steps = [
	{ name: translations.walls[DEFAULT_LOCALE], route: "/walls" },
	{ name: translations.colors[DEFAULT_LOCALE], route: "/colors" },
	{ name: translations.summary[DEFAULT_LOCALE], route: "/summary" },
];

export default function Steps() {
	return (
		<StepsContainer>
			{steps.map((step) => (
				<Step key={step.name}>
					<Link className="step" to={step.route} activeProps={{ className: "step step-active" }}>
						{step.name}
					</Link>
				</Step>
			))}
		</StepsContainer>
	);
}

const StepsContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	color: ${({ theme }) => theme.colors.white};
	background-color: ${({ theme }) => theme.colors.background_dark};
`;

const Step = styled.div`
	flex-basis: 100%;

	.step {
		all: unset;
		box-sizing: border-box;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 4rem;
		border-top: 3px solid transparent;
		font-weight: 400;
		text-transform: uppercase;

		transition:
			color ease 0.15s,
			border-color ease 0.15s;

		&:hover:not(.step-active) {
			cursor: pointer;
			border-color: ${({ theme }) => theme.colors.primary};
		}
	}

	.step-active {
		border-color: ${({ theme }) => theme.colors.primary};
		color: ${({ theme }) => theme.colors.primary};
	}
`;
