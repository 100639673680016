import { FunctionComponent, PropsWithChildren } from "react";
import styled from "styled-components";

const Container = styled.h3`
	padding: ${({ theme }) => theme.padding.small};
	color: ${({ theme }) => theme.colors.black};
	letter-spacing: 1px;
	text-decoration: none !important;
	font-weight: 400;
	font-size: 1.125rem;
`;
const CardTitle: FunctionComponent<PropsWithChildren<object>> = ({ children }) => <Container>{children}</Container>;

export default CardTitle;
