import { EffectComposer, N8AO, ToneMapping } from "@react-three/postprocessing";
import { ToneMappingMode } from "postprocessing";

export default function Effects() {
	return (
		<EffectComposer autoClear>
			<N8AO intensity={1} aoRadius={0.2} aoSamples={5} denoiseSamples={2} denoiseRadius={9} distanceFalloff={0.07} />
			<ToneMapping mode={ToneMappingMode.ACES_FILMIC} />
		</EffectComposer>
	);
}
