import Button from "@/components/primitives/Button";
import { selectors, useAppStore, WallLocation } from "@/stores/appStore";
import translations, { DEFAULT_LOCALE } from "@/translations";
import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute("/_configurator/summary")({
	component: Summary,
});

function Summary() {
	const roof = useAppStore(selectors.getRoofBySelected);
	const walls = useAppStore((store) => store.walls);
	const color = useAppStore((store) => store.color);

	return (
		<div>
			<h3>{translations.roof[DEFAULT_LOCALE]}</h3>
			<p>{roof.name}</p>
			<br />

			<h3>{translations.walls[DEFAULT_LOCALE]}</h3>
			{Object.entries(walls).map(([location, id]) => (
				<p key={id}>{`${translations[location as WallLocation][DEFAULT_LOCALE]}: ${String(id)}`}</p>
			))}
			<br />

			<h3>{translations.color[DEFAULT_LOCALE]}</h3>
			<p>{translations[color][DEFAULT_LOCALE]}</p>
			<br />

			<Button variant="primary">{translations.requestQuote[DEFAULT_LOCALE]}</Button>
		</div>
	);
}
