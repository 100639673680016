import { useAppStore } from "@/stores/appStore";
import { theme } from "@/theme";
import { Grid as DreiGrid } from "@react-three/drei";

export default function Grid() {
	const showGrid = useAppStore((store) => store.showGrid);
	return (
		<DreiGrid
			args={[25, 25]}
			fadeFrom={0}
			fadeDistance={15}
			visible={showGrid}
			cellColor={theme.colors.grey}
			sectionColor={theme.colors.background_dark}
		/>
	);
}
