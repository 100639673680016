import Card, { CardImage, CardTitle } from "@/components/primitives/Card";
import { selectors, useAppStore } from "@/stores/appStore";
import translations, { DEFAULT_LOCALE } from "@/translations";
import { isUndefined, transformTranslation } from "@/utils";
import { createFileRoute } from "@tanstack/react-router";
import { CardGrid } from "./colors";

export const Route = createFileRoute("/_configurator/walls")({
	component: Walls,
});

function Select() {
	const selectedWall = useAppStore(selectors.getWallBySelected);
	const setSelectedWall = useAppStore((store) => store.setSelectedWall);
	const options = useAppStore(selectors.getAvailableWallsBySelected);

	return (
		<CardGrid>
			{options.map((option) => {
				return (
					<button key={option.id} onClick={() => setSelectedWall(option.id)}>
						<Card isActive={option === selectedWall}>
							<CardImage src={option.iconURL} />
							<CardTitle>{option.id}</CardTitle>
						</Card>
					</button>
				);
			})}
		</CardGrid>
	);
}

function Walls() {
	const selectedWall = useAppStore((store) => store.selectedWall);

	if (isUndefined(selectedWall)) {
		return <h3>{translations.selectWall[DEFAULT_LOCALE]}</h3>;
	}

	const wallTranslation = translations[selectedWall][DEFAULT_LOCALE].toLowerCase();
	const selectedWallLabel = transformTranslation("selectedWall", { selectedWall: wallTranslation });

	return (
		<>
			<h3>{selectedWallLabel}</h3>
			<br />
			<Select />
		</>
	);
}
