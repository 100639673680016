import { ENVIRONMENT_FILE } from "@/globals";
import { Environment } from "@react-three/drei";

export default function Lights() {
	return (
		<>
			<spotLight position={[0, 3, 3]} intensity={20} ref={(light) => light?.lookAt(0, 0, 0)} />
			<Environment files={ENVIRONMENT_FILE} environmentIntensity={1} />
		</>
	);
}
