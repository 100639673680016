import Card, { CardTitle } from "@/components/primitives/Card";
import CONFIG, { ColorVariant } from "@/config";
import { useAppStore } from "@/stores/appStore";
import translations, { DEFAULT_LOCALE } from "@/translations";
import { capitalize } from "@/utils";
import { createFileRoute } from "@tanstack/react-router";
import styled from "styled-components";

const colors = ["anthracite", "grey", "duo", "white"];

export const CardGrid = styled.div`
	list-style: none;
	display: grid;
	gap: ${({ theme }) => theme.gap.small};
	grid-template-columns: repeat(2, 50%);

	button {
		all: unset;
		width: 100%;

		&:hover {
			cursor: pointer;
		}
	}
`;

export const Route = createFileRoute("/_configurator/colors")({
	component: Colors,
});

function Colors() {
	const color = useAppStore((store) => store.color);
	const update = useAppStore((store) => store.update);

	return (
		<CardGrid>
			{colors.map((name) => {
				const wallColor = name === "duo" ? "white" : (name as keyof typeof CONFIG.colors);
				const pillarColor = name === "white" ? "white" : "anthracite";

				return (
					<button key={name} title={capitalize(name)} onClick={() => update({ color: name as ColorVariant })}>
						<Card isActive={name === color}>
							<ColorBadge $leftColor={wallColor} $rightColor={pillarColor} />
							<CardTitle>{translations[name as ColorVariant][DEFAULT_LOCALE]}</CardTitle>
						</Card>
					</button>
				);
			})}
		</CardGrid>
	);
}

type ColorBadgeProps = {
	$leftColor: keyof typeof CONFIG.colors;
	$rightColor: keyof typeof CONFIG.colors;
};

const ColorBadge = styled.div<ColorBadgeProps>`
	border-radius: 50%;
	overflow: hidden;
	width: 4rem;
	height: 4rem;
	background-color: #${({ $leftColor }) => CONFIG.colors[$leftColor].getHexString()};

	&:after {
		content: "";
		display: inline-block;
		width: 2rem;
		height: 4rem;
		background-color: #${({ $rightColor }) => CONFIG.colors[$rightColor].getHexString()};
	}
`;
