import { createFileRoute, Outlet } from "@tanstack/react-router";
import styled from "styled-components";
import Scene from "@/components/three/Scene";
import Sidepanel from "@/components/ui/Sidepanel";
import { useEnvironment } from "@react-three/drei";
import { ENVIRONMENT_FILE, MIN_TABLET_WIDTH } from "@/globals";
import Overlay from "@/components/ui/Overlay";

export const Route = createFileRoute("/_configurator")({
	loader: () => useEnvironment.clear({ files: ENVIRONMENT_FILE }), // Prevent issues with gainmaps.
	shouldReload: ({ cause }) => (cause === "enter" ? true : false), // Only re-run loader on initial enter.
	component: Configurator,
});

function Configurator() {
	return (
		<ConfiguratorContainer>
			<Scene className="scene" />
			<Overlay />
			<Sidepanel>
				<Outlet />
			</Sidepanel>
		</ConfiguratorContainer>
	);
}

const ConfiguratorContainer = styled.div`
	display: flex;
	width: 100%;
	height: 100svh;
	background-color: ${({ theme }) => theme.colors.background_light};

	.scene {
		width: 100%;
		height: 100svh;
	}

	@media (max-width: ${MIN_TABLET_WIDTH}) {
		flex-direction: column;

		.scene {
			height: 50svh !important;
		}
	}
`;
