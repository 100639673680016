import CONFIG from "@/config";
import Pillar from "./Pillar";
import { selectors, useAppStore, WallLocation } from "@/stores/appStore";
import Wall from "./Wall";
import Measured, { RenderCallback } from "./Measured";
import { Select, Text } from "@react-three/drei";
import { useCallback } from "react";
import Roof from "./Roof";
import { isObject3D, isUndefined } from "@/utils";
import { Object3D } from "three";

const hasWallLocation = (input: unknown): input is Object3D & { userData: { location: WallLocation } } =>
	isObject3D(input) && input.userData.location !== undefined;

const Shed = () => {
	const update = useAppStore((store) => store.update);
	const showMeasurements = useAppStore((store) => store.showMeasurements);
	const showWalls = useAppStore((store) => store.showWalls);
	const { name, width, depth } = useAppStore(selectors.getRoofBySelected);

	const onSelection = useCallback<(selected: Object3D[]) => void>(
		([firstSelected]) =>
			update({ selectedWall: hasWallLocation(firstSelected) ? firstSelected.userData.location : undefined }),
		[update]
	);

	const renderLabel = useCallback<RenderCallback>(
		(_, axis) => (
			<Text fontSize={0.1} color="black">
				{`${(axis === "X" ? width : axis === "Z" ? depth : 0).toFixed(2)} m`}
			</Text>
		),
		[width, depth]
	);

	// Name may not be defined in the config, but is required to look up the model file in the public directory.
	// Therefore, return early if it is undefined.
	if (isUndefined(name)) {
		return null;
	}

	return (
		<group name="shed">
			<group name="pillars">
				<Measured visible={showMeasurements} showY={false} color="black" render={renderLabel}>
					<Pillar
						type="HV06"
						position={[-(width - CONFIG.wallThickness) / 2, 0, -(depth - CONFIG.wallThickness) / 2]}
						rotation-y={(-0 * Math.PI) / 2}
					/>
					<Pillar
						type="HV06"
						position={[(width - CONFIG.wallThickness) / 2, 0, -(depth - CONFIG.wallThickness) / 2]}
						rotation-y={(-1 * Math.PI) / 2}
					/>
					<Pillar
						type="HV06"
						position={[(width - CONFIG.wallThickness) / 2, 0, (depth - CONFIG.wallThickness) / 2]}
						rotation-y={(-2 * Math.PI) / 2}
					/>
					<Pillar
						type="HV06"
						position={[-(width - CONFIG.wallThickness) / 2, 0, (depth - CONFIG.wallThickness) / 2]}
						rotation-y={(-3 * Math.PI) / 2}
					/>
				</Measured>
			</group>

			<group name="walls" visible={showWalls}>
				<Select onChangePointerUp={onSelection}>
					<Wall location="back" position={[0, 0, -(depth - CONFIG.wallThickness) / 2]} />
					<Wall location="front" position={[0, 0, (depth - CONFIG.wallThickness) / 2]} rotation-y={Math.PI} />
					<Wall location="left" position={[-(width - CONFIG.wallThickness) / 2, 0, 0]} rotation-y={Math.PI / 2} />
					<Wall location="right" position={[(width - CONFIG.wallThickness) / 2, 0, 0]} rotation-y={-Math.PI / 2} />
				</Select>
			</group>
			<Roof type={name} position-y={CONFIG.wallHeight} />
		</group>
	);
};

export default Shed;
