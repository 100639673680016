import { ComponentProps } from "react";
import styled from "styled-components";

export default function CardImage(props: ComponentProps<typeof CardImageContainer>) {
	return <CardImageContainer {...props} />;
}

const CardImageContainer = styled.img`
	width: 100%;
	object-fit: contain;
	aspect-ratio: 1;
`;
