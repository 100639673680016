import { Color } from "three";
import { sortInBuckets } from "./utils";

type Width = "16" | "23";
type Segment = "FF" | "FA" | "FAW" | "DL" | "DR" | "DD" | "SW" | "ST" | "DT";
export type SegmentCode = `${Width}${Segment}`;

export type Wall = {
	id: string;
	segments: [SegmentCode] | [SegmentCode, SegmentCode];
	width: number;
	innerWidth: number;
	enabled?: boolean;
	iconURL?: string;
};

export type Roof = {
	id: string;
	depth: number;
	width: number;
	name?: string;
	enabled?: boolean;
};

type Size = "XS" | "S" | "M" | "L" | "XL";
type SizeMap = Record<Size, number>;

const wallSizes: { outer: SizeMap; inner: SizeMap } = {
	outer: {
		XS: 1.6,
		S: 2.3,
		M: 3.2,
		L: 3.9,
		XL: 4.6,
	},
	inner: {
		XS: 1.305,
		S: 2.055,
		// M: 2.875,
		M: 2.9,
		L: 3.625,
		XL: 4.375,
	},
};

const colors = {
	grey: new Color("rgb(140, 150, 157)"), // RAL 7001
	white: new Color("rgb(236, 236, 231)"), // RAL 9001
	anthracite: new Color("rgb(79, 82, 80)"), // RAL 7043
};

const BASE_CONFIG = {
	colors,
	wallHeight: 2.12,
	wallThickness: 0.13,
	windowHeight: 1.4,
	doorStepHeight: 0.075,
	wallSizes,
	roofs: {
		byId: {
			"00": {
				id: "00",
				depth: wallSizes.outer.XS,
				width: wallSizes.outer.XS,
			},
			"01": {
				id: "01",
				name: "2300-2300",
				depth: wallSizes.outer.S,
				width: wallSizes.outer.S,
				enabled: true,
			},
			"02": {
				id: "02",
				name: "2300-1616",
				depth: wallSizes.outer.S,
				width: wallSizes.outer.M,
				enabled: true,
			},
			"03": {
				id: "03",
				depth: wallSizes.outer.S,
				width: wallSizes.outer.L,
			},
			"04": {
				id: "04",
				depth: wallSizes.outer.S,
				width: wallSizes.outer.XL,
			},
			"05": {
				id: "05",
				name: "1616-1616",
				depth: wallSizes.outer.M,
				width: wallSizes.outer.M,
				enabled: true,
			},
			"06": {
				id: "06",
				depth: wallSizes.outer.M,
				width: wallSizes.outer.L,
			},
			"07": {
				id: "07",
				depth: wallSizes.outer.M,
				width: wallSizes.outer.XL,
			},
			"08": {
				id: "08",
				depth: wallSizes.outer.L,
				width: wallSizes.outer.L,
			},
			"09": {
				id: "09",
				depth: wallSizes.outer.L,
				width: wallSizes.outer.XL,
			},
		} as Record<string, Roof>,
	},
	walls: {
		byId: {
			"16FA-00": {
				id: "16FA-00",
				width: wallSizes.outer.XS,
				innerWidth: wallSizes.inner.XS,
				segments: ["16FA"],
			},
			"16FA16FA-00": {
				id: "16FA16FA-00",
				width: wallSizes.outer.M,
				innerWidth: wallSizes.inner.M,
				segments: ["16FA", "16FA"],
				enabled: true,
				iconURL: "icons/16FA16FA.png",
			},
			"16FA16FAW-00": {
				id: "16FA16FAW-00",
				width: wallSizes.outer.M,
				innerWidth: wallSizes.inner.M,
				segments: ["16FA", "16FAW"],
			},
			"16FF16SW-00": {
				id: "16FF16SW-00",
				width: wallSizes.outer.M,
				innerWidth: wallSizes.inner.M,
				segments: ["16FF", "16SW"],
				enabled: true,
				iconURL: "icons/16FF16SW.png",
			},
			"16SW16FF-00": {
				id: "16SW16FF-00",
				width: wallSizes.outer.M,
				innerWidth: wallSizes.inner.M,
				segments: ["16SW", "16FF"],
				enabled: true,
				iconURL: "icons/16SW16FF.png",
			},
			"16SW16DL-00": {
				id: "16SW16DL-00",
				width: wallSizes.outer.M,
				innerWidth: wallSizes.inner.M,
				segments: ["16SW", "16DL"],
				enabled: false,
			},
			"16FF16ST-00": {
				id: "16FF16ST-00",
				width: wallSizes.outer.M,
				innerWidth: wallSizes.inner.M,
				segments: ["16FF", "16ST"],
			},
			"16FF16DL-00": {
				id: "16FF16DL-00",
				width: wallSizes.outer.M,
				innerWidth: wallSizes.inner.M,
				segments: ["16FF", "16DL"],
			},
			"16DL16FF-00": {
				id: "16DL16FF-00",
				width: wallSizes.outer.M,
				innerWidth: wallSizes.inner.M,
				segments: ["16DL", "16FF"],
				enabled: true,
				iconURL: "icons/16DL16FF.png",
			},
			"16DL16SW-00": {
				id: "16DL16SW-00",
				width: wallSizes.outer.M,
				innerWidth: wallSizes.inner.M,
				segments: ["16DL", "16SW"],
				enabled: false,
			},
			"16FF16DR-00": {
				id: "16FF16DR-00",
				width: wallSizes.outer.M,
				innerWidth: wallSizes.inner.M,
				segments: ["16FF", "16DR"],
			},
			"16FF23SW-00": {
				id: "16FF23SW-00",
				width: wallSizes.outer.L,
				innerWidth: wallSizes.inner.L,
				segments: ["16FF", "23SW"],
			},
			"16FF23ST-00": {
				id: "16FF23ST-00",
				width: wallSizes.outer.L,
				innerWidth: wallSizes.inner.L,
				segments: ["16FF", "23ST"],
			},
			"16FF23DT-00": {
				id: "16FF23DT-00",
				width: wallSizes.outer.L,
				innerWidth: wallSizes.inner.L,
				segments: ["16FF", "23DT"],
			},
			"16FF23DL-00": {
				id: "16FF23DL-00",
				width: wallSizes.outer.L,
				innerWidth: wallSizes.inner.L,
				segments: ["16FF", "23DL"],
			},
			"16FF23DR-00": {
				id: "16FF23DR-00",
				width: wallSizes.outer.L,
				innerWidth: wallSizes.inner.L,
				segments: ["16FF", "23DR"],
			},
			"16FF23DD-00": {
				id: "16FF23DD-00",
				width: wallSizes.outer.L,
				innerWidth: wallSizes.inner.L,
				segments: ["16FF", "23DD"],
			},

			/* 2300 wanden */
			"23FA-00": {
				id: "23FA-00",
				width: wallSizes.outer.S,
				innerWidth: wallSizes.inner.S,
				segments: ["23FF"],
				enabled: true,
				iconURL: "icons/23FA.png",
			},
			"23SW-00": {
				id: "23SW-00",
				width: wallSizes.outer.S,
				innerWidth: wallSizes.inner.S,
				segments: ["23SW"],
				enabled: true,
				iconURL: "icons/23SW.png",
			},
			"23DL-00": {
				id: "23DL-00",
				width: wallSizes.outer.S,
				innerWidth: wallSizes.inner.S,
				segments: ["23DL"],
				enabled: true,
				iconURL: "icons/23DL.png",
			},
			"23FA16FA-00": {
				id: "23FA16FA-00",
				width: wallSizes.outer.L,
				innerWidth: wallSizes.inner.L,
				segments: ["23FA", "16FA"],
			},
			"23FA16FAW-00": {
				id: "23FA16FAW-00",
				width: wallSizes.outer.L,
				innerWidth: wallSizes.inner.L,
				segments: ["23FA", "16FAW"],
			},
			"23FA23FA-00": {
				id: "23FA23FA-00",
				width: wallSizes.outer.XL,
				innerWidth: wallSizes.inner.XL,
				segments: ["23FA", "23FA"],
			},
		} as Record<string, Wall>,
	},
};

const CONFIG = {
	...BASE_CONFIG,
	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	defaultRoof: Object.values(BASE_CONFIG.roofs.byId).find((roof) => roof.enabled)!,
	roofs: {
		byId: Object.fromEntries(Object.entries(BASE_CONFIG.roofs.byId).filter(([_, roof]) => roof.enabled)),
	},
	walls: {
		...BASE_CONFIG.walls,
		byWidth: sortInBuckets(
			Object.values(BASE_CONFIG.walls.byId).filter((wall) => wall.enabled),
			"width"
		),
	},
};

export type RoofCode = keyof typeof CONFIG.roofs.byId;
export type WallCode = keyof typeof CONFIG.walls.byId;
export type ColorVariant = "anthracite" | "grey" | "duo" | "white";
export default CONFIG;
