import useColors from "@/hooks/useColors";
import { selectors, useAppStore } from "@/stores/appStore";
import { useGLTF } from "@react-three/drei";
import { GroupProps } from "@react-three/fiber";
import { FunctionComponent, lazy } from "react";

const PillarComponents = {
	HV06: lazy(() => import("./HV06-00")),
};

export interface PillarProps extends GroupProps {
	type: keyof typeof PillarComponents;
}

const Pillar: FunctionComponent<PillarProps> = ({ type, ...otherProps }) => {
	const pillarColor = useAppStore(selectors.getPillarColor);
	const Pillar = PillarComponents[type];
	const { materials } = useGLTF("/models/HV06-00.glb");

	useColors(materials, {
		["Pillar- Exterior Slats"]: pillarColor,
	});

	return <Pillar {...otherProps} />;
};

export default Pillar;
